





































































































































import {Component, Prop, VModel, Vue} from 'vue-property-decorator';
import Location from '@/models/Location';
import {namespace} from 'vuex-class';
import Customer from '@/models/Customer';
import {Permission} from '@/misc/enums/permission.enum';

const CustomerStore = namespace('customer');

/**
 * Simple component that lists all customer in a table
 */
@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {
    LocationManageMasterDataComponent: () => import(
      /* webpackChunkName: "LocationManageMasterDataComponent" */
      '@/components/location/LocationManageMasterData.component.vue'),
    TableComponent: () => import(
      '@/components/shared/table/Table.component.vue'),
    ActionMenuComponent: () => import(
      '@/components/shared/table/ActionMenu.component.vue'),
  },
})
export default class LocationTableComponent extends Vue {

  @CustomerStore.Action('loadLocationAction')
  public loadLocationAction!: (arg: { locationId: string, shouldBeStored: boolean }) => Promise<Location>;
  @CustomerStore.Action('deleteLocationAction')
  public deleteLocationAction!: (location: Location) => Promise<Location>;
  @CustomerStore.Mutation('storeLocation')
  public storeLocationMutation!: (location: Location | undefined) => void;
  @CustomerStore.Getter('customer')
  public _customer!: Customer;
  @VModel({default: ''})
  public searchString!: string;
  @CustomerStore.Action('loadCustomerAction')
  private loadCustomerAction!: (customerId: string) => Promise<Customer>;
  @Prop({default: []})
  private locations!: Location[];

  @Prop({default: 'calc(100vh - 64px - 50px - 90px)'})
  private height!: string;
  private showLocationModal: boolean = false;
  private showDeleteDialog: boolean = false;
  private showChangeStatusDialog: boolean = false;
  private selectedLocation: Location | undefined = undefined;

  @CustomerStore.Action('editLocationAction')
  private editLocationAction!: (payload: { location: Location, shouldBeStored: boolean }) => Promise<Location>;

  constructor() {
    super();
    this.selectedLocation = new Location();
  }

  private get importantKeys() {
    return {
      name: String,
      address: {street: String, houseNo: String, city: String, postalCode: String},
      contactPerson: {firstName: String, lastName: String},
    };
  }

  get isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();

    return {
      opposite: this.selectedLocation!.active ? inactive : active,
    };
  }

  private get tableHeaders(): any {
    return [
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.NAME'), value: 'name', width: '2'},
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.ADDRESS'), value: 'address', width: '2'},
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.OBJECT_MANAGERS'), value: 'managers', width: '2'},
      {text: this.$t('LOCATIONS_OVERVIEW.TABLE.CONTACT_PERSON'), value: 'contactPerson', width: '2'},
      {text: this.$t('GENERAL.STATUS'), value: 'active', width: '1', class: 'd-flex justify-center'},
      {text: '', value: 'actions', width: '5%', class: 'd-flex justify-start'},

    ];
  }

  private get customer() {
    return this._customer;
  }

  private extractAddress(location: Location) {
    // the default .address in case the object is not parsed to a location...
    return location.derivedAddress || (location.address ?? this.customer.address);
  }

  private extractContactPerson(location: Location) {
    return location.derivedContactPerson || (location.contactPerson ?? this.customer.contactPerson);
  }

  public onLocationDeleteClick(location: Location) {
    this.showDeleteDialog = true;
    this.selectedLocation = location;
  }

  public async onLocationDelete(location: Location) {
    this.showDeleteDialog = false;
    try {
      await this.deleteLocationAction(location);
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_DELETE.ERROR');
    }
  }

  public onLocationStatusToggleClick(event: Event, location: Location) {
    if (this.$userRoleHandler.hasPermission(Permission.LOCATION_UPDATE_OWN)) {
      this.showChangeStatusDialog = true;
      this.selectedLocation = location;
      event.stopPropagation();
    }
  }

  public async onLocationChangeStatus(updatedLocation: Location) {
    this.showChangeStatusDialog = false;
    try {
      updatedLocation.active = !updatedLocation.active;
      await this.editLocationAction({
        location: updatedLocation,
        shouldBeStored: false,
      });
      this.$notifySuccessSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.SUCCESS');
    } catch (e: any) {
      this.$notifyErrorSimplified('CUSTOMER_DASHBOARD.NOTIFICATIONS.LOCATION_EDIT.ERROR');
    }
  }

  public onLocationEditClick(location: Location) {
    this.showLocationModal = true;
    this.selectedLocation = location;
  }

  public activeAreas(location: Location): number {
    if (!location.active || !this._customer.active) {
      return 0;
    }
    return location.areasCountActive ? location.areasCountActive : 0;
  }

  public async onManageLocationFormDone(location: Location) {
    this.closeLocationManageForm();
    const index = this.locations.findIndex((locationEntry) => locationEntry.id === location.id);
    // this reflects location name change in location lists.
    // Otherwise the data has to be loaded again to reflect that change
    this.locations[index] = location;
    // Maybe could work without load...
    await this.loadCustomerAction(this.$route.params.customerId);
  }

  public closeLocationManageForm() {
    this.showLocationModal = false;
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  private onRowClicked(item: Location) {
    this.$router.push({
      name: 'locationDashboard',
      params: {
        companyId: this.$route.params.companyId,
        customerId: this.$route.params.customerId ?? item.customerId,
        locationId: item.id!,
      },
    });
  }
}
